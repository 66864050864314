











import Vue, { PropType } from "vue";
import BaseContentItemSummary from "@/components/contentItems/BaseContentItemSummary.vue";
import DrugContentItemGroupSummaryResult from "@/models/drugContentItemGroupSummaryResult";

/**
 * This component is used to display a summary of a drug with multiple items with the same name.
 */
export default Vue.extend({
  components: { BaseContentItemSummary },
  props: {
    contentItemSummary: Object as PropType<DrugContentItemGroupSummaryResult>,
  },

  methods: {},
});
