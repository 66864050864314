


























import { UserExternalLinkContentItemSummaryResult, RemoveFolioClippingModel, ExternalLinkType } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import BaseContentItemSummary from "@/components/contentItems/BaseContentItemSummary.vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

/**
 * This component is used to display the summary of external links
 */
export default Vue.extend({
  components: { BaseContentItemSummary, DoceoIcon },

  props: {
    contentItemSummary: Object as PropType<UserExternalLinkContentItemSummaryResult>,
    folioId: {
      // This is used to determine if the user is currently viewing content within a folio.
      // Currently used stricly as a pass through to the base content itme
      type: String,
      default: "",
    },
    hideSave: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    DOCEO_ICONS,
  }),

  methods: {
    /**
     * Pass through from the BaseContentItemSummary to the ContentItemSummary component so that we can listen for when items have been removed from a folio
     */
    clippingRemoved(clippingToRemove: RemoveFolioClippingModel) {
      this.$emit("clippingRemoved", clippingToRemove);
    },
  },

  computed: {
    /**
     * Determine if the external link is "Promoted By" or "Voice Of".
     * This will allow us to change the styling if needed. Currently we are not displaying the list of authors if its one of the 2 types of external links
     */
    isPromotedByOrVoiceOf(): boolean {
      return this.isPromotedBy || this.isVoiceOf;
    },
    /**
     * Determine if this external link is a "Promoted By" external link.
     */
    isPromotedBy(): boolean {
      return this.contentItemSummary.promotedBy != undefined;
    },
    /**
     * Determine if this external link is a "Voice Of" external link.
     */
    isVoiceOf(): boolean {
      return this.contentItemSummary.voiceOf != undefined;
    },
    authors(): string | null {
      let authors = this.contentItemSummary.authors?.map(x => `${x}`);
      if (authors && authors.length > 0) {
        return authors.join(", ");
      }
      return null;
    },
    title(): string {
      switch (this.contentItemSummary.externalLinkType) {
        case ExternalLinkType.Facebook: 
          return "Facebook"
        case ExternalLinkType.Instagram: 
          return "Instagram"
        case ExternalLinkType.LinkedIn: 
          return "LinkedIn"
        case ExternalLinkType.Twitter: 
          return "Twitter"
        case ExternalLinkType.YouTube: 
          return "YouTube"
        default:
          return ""
      }
    },
    isExternalSite(): boolean {
      return this.contentItemSummary.externalLinkType == ExternalLinkType.Facebook || 
      this.contentItemSummary.externalLinkType == ExternalLinkType.Twitter || 
      this.contentItemSummary.externalLinkType == ExternalLinkType.Instagram || 
      this.contentItemSummary.externalLinkType == ExternalLinkType.YouTube || 
      this.contentItemSummary.externalLinkType == ExternalLinkType.LinkedIn
    }
  },
});
