




















import { DraftUserArticleContentItemResult } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import BaseContentItemSummary from "@/components/contentItems/BaseContentItemSummary.vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

/**
 * This component is used to display the summary of articles
 */
export default Vue.extend({
  name: "DraftUserArticleContentItemSummary",

  components: { BaseContentItemSummary, DoceoIcon },

  props: {
    contentItemSummary: Object as PropType<DraftUserArticleContentItemResult>,
  },

  data: () => ({
    DOCEO_ICONS,
  }),

  methods: {
    editClicked() {
      this.$emit("edit");
    },
  },

  computed: {
    /**
     * Determine if the article is "Promoted By" or "Voice Of".
     * This will allow us to change the styling if needed. Currently we are not displaying the list of authors if its one of the 2 types of articles
     */
    isPromotedByOrVoiceOf(): boolean {
      return this.isPromotedBy || this.isVoiceOf;
    },
    /**
     * Determine if this article is a "Promoted By" article.
     */
    isPromotedBy(): boolean {
      return this.contentItemSummary.promotedBy != undefined;
    },
    /**
     * Determine if this article is a "Voice Of" article.
     */
    isVoiceOf(): boolean {
      return this.contentItemSummary.voiceOf != undefined;
    },
    /**
     * Return the list of authors separated by commas, up to a maximum.
     */
    authors(): string | null {
      const authors = this.contentItemSummary.authors?.map(x => `${x.firstName} ${x.lastName}`);
      if (authors && authors.length > 0) {
        let shownCount = 4;
        if (authors.length > shownCount) {
          shownCount--;
          const shownAuthors = authors.slice(0, shownCount);
          const othersCount = authors.length - shownCount;
          return `${shownAuthors.join(", ")}, and ${othersCount} others…`;
        } else {
          return authors.join(", ");
        }
      }
      return null;
    },
  },
});
