































import { DrugContentItemSummaryResult, DrugStatus, RemoveFolioClippingModel } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import BaseContentItemSummary from "@/components/contentItems/BaseContentItemSummary.vue";

/**
 * This component is used to display the summary of drugs
 */
export default Vue.extend({
  components: { BaseContentItemSummary },
  props: {
    contentItemSummary: Object as PropType<DrugContentItemSummaryResult>,
    folioId: {
      // This is used to determine if the user is currently viewing content within a folio.
      // Currently used stricly as a pass through to the base content itme
      type: String,
      default: "",
    },
  },

  methods: {
    /**
     * Pass through from the BaseContentItemSummary to the ContentItemSummary component so that we can listen for when items have been removed from a folio
     */
    clippingRemoved(clippingToRemove: RemoveFolioClippingModel) {
      this.$emit("clippingRemoved", clippingToRemove);
    },
  },

  computed: {
    /**
     * Return a string representation of the drug status
     */
    drugStatus(): string {
      // export enum DrugStatus {
      switch (this.contentItemSummary.drugStatus) {
        case DrugStatus.Approved:
          return "Approved";
        case DrugStatus.Marketed:
          return "Marketed";
        case DrugStatus.CancelledPreMarket:
          return "Cancelled PreMarket";
        case DrugStatus.CancelledPostMarket:
          return "Cancelled PostMarket";
        case DrugStatus.Dormant:
          return "Dormant";
        default:
          return "Unknown";
      }
    },
    /**
     * Return the color appropriate based on the status
     */
    statusColor(): string {
      // export enum DrugStatus {
      switch (this.contentItemSummary.drugStatus) {
        case DrugStatus.Approved:
          return "green";
        case DrugStatus.Marketed:
          return "orange"; // Need color
        case DrugStatus.CancelledPreMarket:
          return "purple"; // Need color
        case DrugStatus.CancelledPostMarket:
          return "yellow"; // Need color
        case DrugStatus.Dormant:
          return "red"; // Need color
        default:
          return "Unknown"; // Need color
      }
    },
  },
});
