

























































import { ContentAuthorResult, ContentsClient, FolioContentItemResult, FolioContentItemSummaryResult, FolioShareLevel, RemoveFolioClippingModel } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import BaseContentItemSummary from "@/components/contentItems/BaseContentItemSummary.vue";
import CreateFolioDialog from "@/components/contentItems/folios/CreateFolioDialog.vue";
import DoceoImage from "@/components/DoceoImage.vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";
import { SHARE_LEVEL_ICON, SHARE_LEVEL_TEXT } from "@/constants/folios";
import { mapGetters } from "vuex";

/**
 * This component is used to display the summary of folios
 */
export default Vue.extend({
  components: { BaseContentItemSummary, CreateFolioDialog, DoceoImage, DoceoIcon },
  data: () => ({
    isEditFolioDialogOpen: false, // Whether the edit folio dialog is open or not
    DOCEO_ICONS,
    isHovered: false,
    fullContentItem: null as FolioContentItemResult | null, // used for the folio editor
  }),
  props: {
    contentItemSummary: Object as PropType<FolioContentItemSummaryResult>,
    folioId: {
      // This is used to determine if the user is currently viewing content within a folio.
      // Currently used stricly as a pass through to the base content itme
      type: String,
      default: "",
    },
  },

  methods: {
    /**
     * Close the edit folio dialog
     */
    closeFolioDialog() {
      this.isEditFolioDialogOpen = false;
    },
    /**
     * The edit folio has been confirmed and we can close the dialog
     */
    editFolioConfirmed() {
      this.isEditFolioDialogOpen = false;
    },
    /**
     * Open the dialog to edit a folio
     */
    async editFolioClicked() {
      // load in the complete folio
      let client = new ContentsClient();
      this.fullContentItem = await client.getContentItem(this.contentItemSummary.contentItemId) as FolioContentItemResult;

      this.isEditFolioDialogOpen = true;
    },
    getCreatorDisplay(creator: ContentAuthorResult | undefined) {
      if (creator) {
        return `${creator.firstName} ${creator.lastName}`;
      }
      return ``;
    },
    /**
     * Pass through from the BaseContentItemSummary to the ContentItemSummary component so that we can listen for when items have been removed from a folio
     */
    clippingRemoved(clippingToRemove: RemoveFolioClippingModel) {
      this.$emit("clippingRemoved", clippingToRemove);
    },
  },

  computed: {
    ...mapGetters(["userId"]),
    shareLevelIcon(): string {
      switch (this.contentItemSummary.shareLevel) {
        case FolioShareLevel.Public:
          return SHARE_LEVEL_ICON.PUBLIC;
        case FolioShareLevel.Private:
          return SHARE_LEVEL_ICON.PRIVATE;
        case FolioShareLevel.Restricted:
          return SHARE_LEVEL_ICON.RESTRICTED;
      }
      return SHARE_LEVEL_ICON.RESTRICTED;
    },
    shareLevelText(): string {
      switch (this.contentItemSummary.shareLevel) {
        case FolioShareLevel.Public:
          return SHARE_LEVEL_TEXT.PUBLIC;
        case FolioShareLevel.Private:
          return SHARE_LEVEL_TEXT.PRIVATE;
        case FolioShareLevel.Restricted:
          return SHARE_LEVEL_TEXT.RESTRICTED;
      }
      return SHARE_LEVEL_TEXT.RESTRICTED;
    },
    /**
     * Flag to indicate if the folio is created by the logged in user
     */
    userIsAuthor(): boolean {
      return this.contentItemSummary.creator?.userId == this.userId; // TODO: Actual logic for this
    },
  },
});
