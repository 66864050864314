





import { DecisionContentItemSummaryResult, DrugStatus, RemoveFolioClippingModel } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import BaseContentItemSummary from "@/components/contentItems/BaseContentItemSummary.vue";

/**
 * This component is used to display the summary of decisions
 */
export default Vue.extend({
  components: { BaseContentItemSummary },
  props: {
    contentItemSummary: Object as PropType<DecisionContentItemSummaryResult>,
    folioId: {
      // This is used to determine if the user is currently viewing content within a folio.
      // Currently used stricly as a pass through to the base content itme
      type: String,
      default: "",
    },
  },

  methods: {
    /**
     * Pass through from the BaseContentItemSummary to the ContentItemSummary component so that we can listen for when items have been removed from a folio
     */
    clippingRemoved(clippingToRemove: RemoveFolioClippingModel) {
      this.$emit("clippingRemoved", clippingToRemove);
    },
  },

  computed: {
    /**
     * Remove the HTML from the summary message
     */
    contentItemSummaryStripped(): DecisionContentItemSummaryResult {
      let contentItem = this.contentItemSummary;
      contentItem.summary = contentItem.summary?.replace(/<[^>]*>?/gm, "").replaceAll("&nbsp;", " ");
      return contentItem;
    },
  },
});
