




























import { DraftUserExternalLinkContentItemResult } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import BaseContentItemSummary from "@/components/contentItems/BaseContentItemSummary.vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

/**
 * This component is used to display the summary of external links
 */
export default Vue.extend({
  name: "DraftUserExternalLinkContentItemSummary",

  components: { BaseContentItemSummary, DoceoIcon },

  props: {
    contentItemSummary: Object as PropType<DraftUserExternalLinkContentItemResult>,
  },

  data: () => ({
    DOCEO_ICONS,
  }),

  methods: {
    editClicked() {
      this.$emit("edit");
    },
  },

  computed: {
    /**
     * Determine if the external link is "Promoted By" or "Voice Of".
     * This will allow us to change the styling if needed. Currently we are not displaying the list of authors if its one of the 2 types of external links
     */
    isPromotedByOrVoiceOf(): boolean {
      return this.isPromotedBy || this.isVoiceOf;
    },
    /**
     * Determine if this external link is a "Promoted By" external link.
     */
    isPromotedBy(): boolean {
      return this.contentItemSummary.promotedBy != undefined;
    },
    /**
     * Determine if this external link is a "Voice Of" external link.
     */
    isVoiceOf(): boolean {
      return this.contentItemSummary.voiceOf != undefined;
    },
    authors(): string | null {
      let authors = this.contentItemSummary.authors?.map(x => `${x.firstName} ${x.lastName}`);
      if (authors && authors.length > 0) {
        return authors.join(", ");
      }
      return null;
    },
  },
});
