








































































import {
  AttachmentClient,
  ContentItemSummaryResult,
  FileResponse,
  GetAttachmentDto,
  FolioClient,
  RemoveFolioClippingModel,
  Folio,
  UserExternalLinkContentItemSummaryResult,
  ContentsClient,
} from "@/api/DoceoApi";
import Vue, { PropType } from "vue";

import { ContentItemType } from "@/api/DoceoApi";
import DoceoImage from "../DoceoImage.vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";
import { getContentSummaryColor, getContentSummarySavedColor } from "@/constants/contentColors";
import { getContentIcon, getExternalLinkIcon, getSaveIcon } from "@/constants/contentIcons";
import AddToFolioDialog from "@/components/contentItems/folios/AddToFolioDialog.vue";
import { mapActions } from "pinia";
import { useErrorStore } from "@/store/errorStore";
import { getUserFriendlyContentItemType } from "@/constants/contentNames";
import { mapGetters } from "vuex";

/**
 * This is the base level component for the summary of all content items. This is used to have a simliar look & feel to content items.
 */
export default Vue.extend({
  components: { DoceoImage, DoceoIcon, AddToFolioDialog },
  props: {
    contentItemSummary: Object as PropType<ContentItemSummaryResult>,
    actionText: {
      default: "Read More...",
      type: String,
    },
    includeDate: {
      // Whether the date should be shown. Currently this is shown for all content items expect folios.
      default: true,
      type: Boolean,
    },
    includeDivider: {
      // Whether the divider should be shown. Currently this is shown for all content items expect folios.
      default: true,
      type: Boolean,
    },
    hideSave: {
      // Whether the content item should be able to be saved to a folio. Currently this is all content items expect for folios
      default: false,
      type: Boolean,
    },
    folioId: {
      // When the user is viewing a content item that belongs to a folio, this will be populated with the folio id
      type: String,
      default: "",
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    preventNavigation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isAddToFolioDialogOpen: false,
    selectedContentItemSummary: null as ContentItemSummaryResult | null,
    isHovered: false,
  }),
  methods: {
    ...mapActions(useErrorStore, ["addError"]),
    /**
     * When the save icon is clicked.
     * If the item was already saved, then we remove it, otherwise we add it
     */
    saveIconClicked() {
      if (this.folioId) {
        this.removeFromFolio();
      } else {
        this.addToFolio();
      }
    },
    /**
     * Navigate to the page showing the full details of the content item
     */
    navigateToItem() {
      if (this.preventNavigation) return;

      // log the content item view
      try {
        const contentsClient = new ContentsClient();
        contentsClient.addContentItemVersionView(this.contentItemSummary.versionId);
      } catch (e) {
        // do nothing
      }

      this.$router.push({
        name: "ContentItemDetails",
        params: { contentItemId: this.contentItemSummary.versionId },
      });
    },
    /**
     * Open the dialog to add the item to a folio
     */
    addToFolio() {
      this.isAddToFolioDialogOpen = true;
    },
    /**
     * Remove the item from a folio.
     * This will only be possible, if a folioId has been passed in
     */
    async removeFromFolio() {
      if (this.folioId != "") {
        let clippingToRemove: RemoveFolioClippingModel = new RemoveFolioClippingModel({
          contentItemId: this.contentItemSummary.contentItemId,
          folioId: this.folioId,
        });

        const folioClient = new FolioClient();
        await folioClient
          .removeFolioClipping(clippingToRemove.folioId, clippingToRemove)
          .then(() => {
            this.$emit("clippingRemoved", clippingToRemove);
          })
          .catch(() => {
            this.addError("Error removing item");
          });
      }
    },
    /**
     * Called when the item has been added to a folio
     */
    addedToFolio() {
      this.isAddToFolioDialogOpen = false;
    },
  },
  computed: {
    ...mapGetters(["hasSponsorRole"]),
    /**
     * Determine which save icon to display.
     * If the content item is being hovered or has been saved, it should be filled in, otherwise it should be an outline
     */
    saveIcon(): string {
      if (this.isSaved) {
        return getSaveIcon(this.contentItemSummary);
      } else {
        return DOCEO_ICONS.SAVE_DEFAULT;
      }
    },
    saveHoverIcon(): string {
      return getSaveIcon(this.contentItemSummary);
    },

    /**
     * Display the icon appropriate for each type of content item
     */
    defaultIcon(): string {
      return getContentIcon(this.contentItemSummary)
    },
    /**
     * Determine the color that should be used for each type of content
     */
    defaultColor(): string {
      if (this.isPromotedByOrVoiceOf) {
        return "";
      }
      return getContentSummaryColor(this.contentItemSummary);
    },
    backgroundColor(): string {
      if (!this.isSaved) {
        return "inherit";
      }
      return getContentSummarySavedColor(this.contentItemSummary);
    },
    userFriendlyContentItemType(): string {
      return getUserFriendlyContentItemType(this.contentItemSummary.contentItemType);
    },
    /**
     * Determine if the content is either "Promoted By" or "Voice Of". This is used for styling differently for sponsered content
     */
    isPromotedByOrVoiceOf(): boolean {
      return this.isPromotedBy || this.isVoiceOf;
    },
    /**
     * Determine if the content is "Promoted By" content. This is used mainly for styling purposes.
     */
    isPromotedBy(): boolean {
      return this.contentItemSummary.promotedBy != undefined;
    },
    /**
     * Determine if the content is "Voice Of" content. This is used mainly for styling purposes.
     */
    isVoiceOf(): boolean {
      return this.contentItemSummary.voiceOf != undefined;
    },
    /**
     * Determine if the additional header slot is being used.
     */
    hasAdditionalHeaderSlot(): boolean {
      return !!this.$slots["additionalHeader"];
    },
    /**
     * Return whether the content item has an attachment
     */
    attachmentId(): string | null {
      return this.contentItemSummary.attachmentId ?? null;
    },
    imgHeight(): string {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": // < 600px
          return "100px";
        case "sm": // 600px > < 960px
          return "200px";
        case "md": // 960px > < 1264px
          return "200px";
        case "lg": // 1264px > < 1904px
          return "250px";
        case "xl": //	> 1904px
          return "250px";
        default:
          return "100px";
      }
    },
    /**
     * Determine if the content is saved to any folios
     */
    isSaved(): boolean {
      return (this.contentItemSummary.savedToFolioIds && this.contentItemSummary.savedToFolioIds?.length > 0) ?? false;
    },
  },
});
