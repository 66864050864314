















import { RemoveFolioClippingModel, TrialContentItemSummaryResult } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import BaseContentItemSummary from "@/components/contentItems/BaseContentItemSummary.vue";

/**
 * This component is used to display the summary of trials
 */
export default Vue.extend({
  components: { BaseContentItemSummary },
  props: {
    contentItemSummary: Object as PropType<TrialContentItemSummaryResult>,
    folioId: {
      // This is used to determine if the user is currently viewing content within a folio.
      // Currently used stricly as a pass through to the base content itme
      type: String,
      default: "",
    },
  },

  methods: {
    /**
     * Pass through from the BaseContentItemSummary to the ContentItemSummary component so that we can listen for when items have been removed from a folio
     */
    clippingRemoved(clippingToRemove: RemoveFolioClippingModel) {
      this.$emit("clippingRemoved", clippingToRemove);
    },
  },

  computed: {
    /**
     * Determine an appropriate message for age range based on the min/max age.
     */
    ageRange(): string {
      let message = "Age Range: ";
      let minAgeInYears = this.contentItemSummary.minAgeInYears;
      let maxAgeInYears = this.contentItemSummary.maxAgeInYears;

      if (minAgeInYears > 0 && maxAgeInYears > 0) {
        return `${message} ${minAgeInYears} - ${maxAgeInYears}`;
      } else if (minAgeInYears <= 0 && maxAgeInYears > 0) {
        return `${message} Up to ${maxAgeInYears}`;
      } else if (minAgeInYears > 0 && maxAgeInYears <= 0) {
        return `${message} ${minAgeInYears}+`;
      } else if (minAgeInYears <= 0 && maxAgeInYears <= 0) {
        return `No age range specified`;
      } else {
        return `${message} Unkown`;
      }
    },
  },
});
