
















import {
  ContentItemSummaryResult,
  ContentItemType,
  RemoveFolioClippingModel,
} from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import ArticleContentItemSummary from "./articles/ArticleContentItemSummary.vue";
import DrugContentItemSummary from "./drugs/DrugContentItemSummary.vue";
import DrugBrandContentItemSummary from "./drugBrands/DrugBrandContentItemSummary.vue";
import TrialContentItemSummary from "./trials/TrialContentItemSummary.vue";
import FolioContentItemSummary from "./folios/FolioContentItemSummary.vue";
import DecisionContentItemSummary from "./decisions/DecisionContentItemSummary.vue";
import UserArticleContentItemSummary from "./userContent/articles/UserArticleContentItemSummary.vue";
import DraftUserArticleContentItemSummary from "./drafts/articles/DraftUserArticleContentItemSummary.vue";
import UserExternalLinkContentItemSummary from "./userContent/externalLinks/UserExternalLinkContentItemSummary.vue";
import DraftUserExternalLinkContentItemSummary from "./drafts/externalLinks/DraftUserExternalLinkContentItemSummary.vue";
import BaseContentItemSummary from "@/components/contentItems/BaseContentItemSummary.vue";

/**
 * This is the high level component for summaries. This is the component that will always be called when displaying summaries.
 * From here, the specific summary component will be displayed which all use the BaseContentItemSummary so that we can use slots to
 * update them as necessary for the different types of items
 */
export default Vue.extend({
  props: {
    contentItemSummary: Object as PropType<ContentItemSummaryResult>,
    folioId: {
      type: String,
      default: "",
    },
    hideSave: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ArticleContentItemSummary,
    DrugContentItemSummary,
    DrugBrandContentItemSummary,
    TrialContentItemSummary,
    FolioContentItemSummary,
    DecisionContentItemSummary,
    UserArticleContentItemSummary,
    DraftUserArticleContentItemSummary,
    UserExternalLinkContentItemSummary,
    DraftUserExternalLinkContentItemSummary,
    BaseContentItemSummary,
  },

  methods: {
    /**
     * Emit an event indicating that a clipping has been removed
     */
    clippingRemoved(clippingToRemove: RemoveFolioClippingModel) {
      this.$emit("clippingRemoved", clippingToRemove);
    },
  },

  computed: {
    /**
     * Render the appropriate component based on the type of Content Item.
     */
    componentType(): object | undefined {
      switch (this.contentItemSummary.contentItemType) {
        case ContentItemType.Article:
          return ArticleContentItemSummary;
        case ContentItemType.Drug:
          return DrugContentItemSummary;
        case ContentItemType.DrugBrandGroup:
          return DrugBrandContentItemSummary;
        case ContentItemType.Trial:
          return TrialContentItemSummary;
        case ContentItemType.Folio:
          return FolioContentItemSummary;
        case ContentItemType.Decision:
          return DecisionContentItemSummary;
        case ContentItemType.DraftUserArticle:
          return DraftUserArticleContentItemSummary;
        case ContentItemType.UserArticle:
          return UserArticleContentItemSummary;
        case ContentItemType.DraftUserExternalLink:
          return DraftUserExternalLinkContentItemSummary;
        case ContentItemType.UserExternalLink:
          return UserExternalLinkContentItemSummary;
        default:
          return BaseContentItemSummary;
      }
    },
  },
});
